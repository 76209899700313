import Vue from "vue";
import Router from "vue-router";
import RouterPermission from "./middleware/RoutePermission";
import { store } from "../store";
Vue.use(Router);

const router = new Router({
  mode: "history",
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),
});
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) return context.next;
  return (...parameters) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

function configRoutes() {
  return [
    {
      path: "/",
      name: "Login",
      component: () => import("@/views/pages/Login"),
    },
    {
      path: "/home",
      name: "Home",
      redirect: "/welcome",
      component: () => import("@/containers/TheContainer"),
      meta: { requiresAuth: true, middleware: RouterPermission, lv: 1 },
      children: [
        {
          path: "/welcome",
          name: "Welcome",
          component: () => import("@/views/pages/Welcome"),
          meta: { requiresAuth: true, middleware: RouterPermission },
        },
        {
          path: "",
          name: "",
          redirect: "/banner",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/banner",
              redirect: "/banner",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              name: "Banner",
              meta: {
                requiresAuth: true,
                activeUrl: "/banner",
                middleware: RouterPermission,
                parent: "banner",
                lv: 1,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () => import("@/views/pages/banner/Index"),
                  meta: {
                    requiresAuth: true,
                    activeUrl: "/banner",
                    parent: "banner",
                    middleware: RouterPermission,
                    lv: 2,
                  },
                },
                {
                  path: "details/:id",
                  name: "Detail",
                  component: () => import("@/views/pages/banner/Details"),
                  meta: {
                    requiresAuth: true,
                    isChildren: true,
                    activeUrl: "/banner",
                    parent: "banner",
                    middleware: RouterPermission,
                    lv: 3,
                  },
                },
              ],
            },
          ],
          meta: { requiresAuth: true, middleware: RouterPermission },
        },
        {
          path: "",
          name: "",
          redirect: "/productbanner",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/productbanner",
              redirect: "/productbanner",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              name: "Product Banner",
              meta: {
                requiresAuth: true,
                activeUrl: "/productbanner",
                parent: "productbanner",
                middleware: RouterPermission,
                lv: 1,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () => import("@/views/pages/productbanner/Index"),
                  meta: {
                    requiresAuth: true,
                    activeUrl: "/productbanner",
                    parent: "productbanner",
                    middleware: RouterPermission,
                    lv: 2,
                  },
                },
                {
                  path: "details/:id",
                  name: "Detail",
                  component: () =>
                    import("@/views/pages/productbanner/Details"),
                  meta: {
                    requiresAuth: true,
                    isChildren: true,
                    activeUrl: "/productbanner",
                    parent: "productbanner",
                    middleware: RouterPermission,
                    lv: 3,
                  },
                },
              ],
            },
          ],
          meta: { requiresAuth: true, middleware: RouterPermission },
        },
        {
          path: "/category",
          name: "Category",
          component: () => import("@/views/pages/category/Index"),
          meta: {
            requiresAuth: true,
            middleware: RouterPermission,
            lv: 2,
            activeUrl: "/category",
            parent: "Product Mobile ",
          },
        },
        {
          path: "",
          name: "",
          redirect: "/productmobile",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/productmobile",
              redirect: "/productmobile",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              name: "Product Mobile",
              meta: {
                requiresAuth: true,
                activeUrl: "/productmobile",
                parent: "productmobile",
                middleware: RouterPermission,
                lv: 2,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () => import("@/views/pages/product/Index"),
                  meta: {
                    requiresAuth: true,
                    activeUrl: "/productmobile",
                    parent: "Product Mobile",
                    middleware: RouterPermission,
                    lv: 2,
                  },
                },
                {
                  path: "details/:id",
                  name: "Detail",
                  component: () => import("@/views/pages/product/Details"),
                  meta: {
                    requiresAuth: true,
                    isChildren: true,
                    activeUrl: "/productmobile",
                    parent: "productmobile",
                    middleware: RouterPermission,
                    lv: 3,
                  },
                },
              ],
            },
          ],
          meta: { requiresAuth: true, middleware: RouterPermission },
        },
        {
          path: "",
          name: "",
          redirect: "/saleschannel",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/saleschannel",
              redirect: "/saleschannel",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              name: "Sales Channel",
              meta: {
                requiresAuth: true,
                activeUrl: "/saleschannel",
                parent: "saleschannel",
                middleware: RouterPermission,
                lv: 2,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () => import("@/views/pages/salesChannel"),
                  meta: {
                    requiresAuth: true,
                    activeUrl: "/saleschannel",
                    parent: "saleschannel",
                    middleware: RouterPermission,
                    lv: 2,
                  },
                },
                {
                  path: "details/:id",
                  name: "Detail",
                  component: () => import("@/views/pages/salesChannel/Details"),
                  meta: {
                    requiresAuth: true,
                    isChildren: true,
                    activeUrl: "/saleschannel",
                    parent: "saleschannel",
                    middleware: RouterPermission,
                    lv: 2,
                  },
                },
              ],
            },
          ],
          meta: { requiresAuth: true, middleware: RouterPermission },
        },
        {
          path: "",
          name: "",
          redirect: "/video",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/video",
              redirect: "/video",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              name: "Video",
              meta: {
                requiresAuth: true,
                activeUrl: "/video",
                parent: "video",
                lv: 1,
                middleware: RouterPermission,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () => import("@/views/pages/video/Index"),
                  meta: {
                    requiresAuth: true,
                    activeUrl: "/video",
                    parent: "video",
                    middleware: RouterPermission,
                    lv: 2,
                  },
                },
                {
                  path: "details/:id",
                  name: "Detail",
                  component: () => import("@/views/pages/video/Details"),
                  meta: {
                    requiresAuth: true,
                    isChildren: true,
                    activeUrl: "/video",
                    parent: "video",
                    middleware: RouterPermission,
                    lv: 3,
                  },
                },
              ],
            },
          ],
          meta: { requiresAuth: true, middleware: RouterPermission },
        },
        {
          path: "",
          name: "",
          redirect: "/privilegecode",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/privilegecode",
              redirect: "/privilegecode",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              name: "Privilege",
              meta: {
                requiresAuth: true,
                activeUrl: "/privilegecode",
                parent: "privilegecode",
                middleware: RouterPermission,
                lv: 1,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () => import("@/views/pages/privilegeCode/Index"),
                  meta: {
                    requiresAuth: true,
                    activeUrl: "/privilegecode",
                    parent: "privilegecode",
                    middleware: RouterPermission,
                    lv: 2,
                  },
                },
                {
                  path: "details/:id",
                  name: "Detail",
                  component: () =>
                    import("@/views/pages/privilegeCode/Details"),
                  meta: {
                    requiresAuth: true,
                    isChildren: true,
                    activeUrl: "/privilegecode",
                    parent: "privilegecode",
                    middleware: RouterPermission,
                    lv: 3,
                  },
                },
              ],
            },
          ],
          meta: { requiresAuth: true, middleware: RouterPermission },
        },
        {
          path: "",
          name: "",
          redirect: "/stampcard",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/stampcard",
              redirect: "/stampcard",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              name: "Beauty Passport",
              meta: {
                requiresAuth: true,
                activeUrl: "/stampcard",
                parent: "stampcard",
                middleware: RouterPermission,
                lv: 1,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () => import("@/views/pages/stampcard/Index"),
                  meta: {
                    requiresAuth: true,
                    activeUrl: "/stampcard",
                    parent: "stampcard",
                    middleware: RouterPermission,
                    lv: 2,
                  },
                },
                {
                  path: "details/:id",
                  name: "Detail",
                  component: () => import("@/views/pages/stampcard/Details"),
                  meta: {
                    requiresAuth: true,
                    isChildren: true,
                    activeUrl: "/stampcard",
                    parent: "stampcard",
                    middleware: RouterPermission,
                    lv: 3,
                  },
                },
              ],
            },
          ],
          meta: { requiresAuth: true, middleware: RouterPermission },
        },
        {
          path: "",
          name: "",
          redirect: "/coupon",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/coupon",
              redirect: "/coupon",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              name: "Coupon",
              meta: {
                requiresAuth: true,
                activeUrl: "/coupon",
                parent: "coupon",
                middleware: RouterPermission,
                lv: 1,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () => import("@/views/pages/coupon/index"),
                  meta: {
                    requiresAuth: true,
                    activeUrl: "/coupon",
                    parent: "coupon",
                    middleware: RouterPermission,
                    lv: 2,
                  },
                },
                {
                  path: "details/:id",
                  name: "Detail",
                  component: () => import("@/views/pages/coupon/Detail"),
                  meta: {
                    requiresAuth: true,
                    isChildren: true,
                    activeUrl: "/coupon",
                    parent: "coupon",
                    middleware: RouterPermission,
                    lv: 3,
                  },
                },
              ],
            },
          ],
          meta: { requiresAuth: true, middleware: RouterPermission },
        },
        {
          path: "",
          name: "",
          redirect: "/promotionnews",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/promotionnews",
              redirect: "/promotionnews",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              name: "New/Promotion News",
              meta: {
                requiresAuth: true,
                activeUrl: "/promotionnews",
                parent: "promotionnews",
                middleware: RouterPermission,
                lv: 1,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () => import("@/views/pages/promotionNews/Index"),
                  meta: {
                    requiresAuth: true,
                    activeUrl: "/promotionnews",
                    parent: "promotionnews",
                    middleware: RouterPermission,
                    lv: 2,
                  },
                },
                {
                  path: "details/:id",
                  name: "Detail",
                  component: () =>
                    import("@/views/pages/promotionNews/Details"),
                  meta: {
                    requiresAuth: true,
                    isChildren: true,
                    activeUrl: "/promotionnews",
                    parent: "promotionnews",
                    middleware: RouterPermission,
                    lv: 3,
                  },
                },
              ],
            },
          ],
          meta: { requiresAuth: true, middleware: RouterPermission },
        },
        {
          path: "",
          name: "",
          redirect: "/notification",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/notification",
              redirect: "/notification",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              name: "Notification",
              meta: {
                requiresAuth: true,
                activeUrl: "/notification",
                parent: "notification",
                middleware: RouterPermission,
                lv: 1,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () => import("@/views/pages/notification/Index"),
                  meta: {
                    requiresAuth: true,
                    activeUrl: "/notification",
                    parent: "notification",

                    lv: 2,
                  },
                },
                {
                  path: "details/:id",
                  name: "Detail",
                  component: () => import("@/views/pages/notification/Details"),
                  meta: {
                    requiresAuth: true,
                    isChildren: true,
                    activeUrl: "/notification",
                    parent: "notification",
                    lv: 3,
                  },
                },
              ],
            },
          ],
          meta: { requiresAuth: true, middleware: RouterPermission },
        },
        {
          path: "",
          name: "",
          redirect: "/form",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          meta: { requiresAuth: true, middleware: RouterPermission },
          children: [
            {
              path: "/form",
              name: "Campaign Forms",
              redirect: "/form",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              meta: {
                requiresAuth: true,
                middleware: RouterPermission,
                activeUrl: "/form",
                isChildren: true,
                lv: 2,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () => import("@/views/pages/form"),
                  meta: {
                    requiresAuth: true,
                    activeUrl: "/form",
                    parent: "form",
                    middleware: RouterPermission,
                    lv: 2,
                  },
                },
                {
                  path: "detail/:id",
                  name: "Details",
                  component: () => import("@/views/pages/form/detail/Forms"),
                  meta: {
                    requiresAuth: true,
                    middleware: RouterPermission,
                    isChildren: true,
                    activeUrl: "/form",
                  },
                },

                {
                  path: "/campaign/detail/:id",
                  name: "Campaign",
                  component: () => import("@/views/pages/form/report/Campaign"),
                  meta: {
                    requiresAuth: true,
                    middleware: RouterPermission,
                    isChildren: true,
                    activeUrl: "/form",
                  },
                },
              ],
            },
          ],
        },
        {
          path: "",
          name: "",
          redirect: "/automation",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/automation",
              redirect: "/automation",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              name: "Automation",
              meta: {
                requiresAuth: true,
                activeUrl: "/automation",
                middleware: RouterPermission,
                parent: "automation",
                lv: 1,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () => import("@/views/pages/automation/index"),
                  meta: {
                    requiresAuth: true,
                    activeUrl: "/automation",
                    parent: "automation",
                    middleware: RouterPermission,
                    lv: 2,
                  },
                },
                {
                  path: "detail/:id",
                  name: "Detail",
                  component: () =>
                    import("@/views/pages/automation/detail/Detail"),
                  meta: {
                    requiresAuth: true,
                    isChildren: true,
                    activeUrl: "/automation",
                    parent: "automation",
                    middleware: RouterPermission,
                    lv: 3,
                  },
                },
              ],
            },
          ],
          meta: { requiresAuth: true, middleware: RouterPermission },
        },

        {
          path: "/automation/imagemap",
          name: "Automation Image Map",
          component: () =>
            import("@/views/pages/automation/detail/imageMap/ImageMap.vue"),
          meta: {
            requiresAuth: true,
            middleware: RouterPermission,
            isChildren: true,
            activeUrl: "/automation",
          },
        },
        {
          path: "",
          name: "",
          redirect: "/automation/sendmessage",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/automation/sendmessage",
              redirect: "/automation/sendmessage",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              name: "Send Message",
              meta: {
                requiresAuth: true,
                activeUrl: "/automation/sendmessage",
                parent: "automation",
                middleware: RouterPermission,
                lv: 1,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () =>
                    import(
                      "@/views/pages/automation/segmentation/SendMessage.vue"
                    ),
                  meta: {
                    requiresAuth: true,
                    activeUrl: "/automation/sendmessage",
                    parent: "automation",
                    middleware: RouterPermission,
                    lv: 2,
                  },
                },
                {
                  path: "/sendmessage/detail/:id",
                  name: "Detail",
                  component: () =>
                    import(
                      "@/views/pages/automation/segmentation/detail/Detail"
                    ),
                  meta: {
                    requiresAuth: true,
                    isChildren: true,
                    activeUrl: "/automation/sendmessage",
                    parent: "automation",
                    middleware: RouterPermission,
                    lv: 3,
                  },
                },
              ],
            },
          ],
          meta: { requiresAuth: true, middleware: RouterPermission },
        },
        {
          path: "",
          name: "",
          redirect: "/membertier",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/membertier",
              redirect: "/membertier",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              name: "Member Tier",
              meta: {
                requiresAuth: true,
                activeUrl: "/membertier",
                parent: "membertier",
                middleware: RouterPermission,
                lv: 1,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () => import("@/views/pages/memberTier/index"),
                  meta: {
                    requiresAuth: true,
                    activeUrl: "/membertier",
                    parent: "membertier",
                    middleware: RouterPermission,
                    lv: 2,
                  },
                },
                {
                  path: "detail/:id",
                  name: "Detail",
                  component: () =>
                    import("@/views/pages/memberTier/detail/Detail"),
                  meta: {
                    requiresAuth: true,
                    isChildren: true,
                    activeUrl: "/membertier",
                    parent: "membertier",
                    middleware: RouterPermission,
                    lv: 3,
                  },
                },
              ],
            },
          ],
          meta: { requiresAuth: true, middleware: RouterPermission },
        },
        {
          path: "",
          name: "",
          redirect: "/redeem",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/redeem",
              redirect: "/redeem",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              name: "Redeem",
              meta: {
                requiresAuth: true,
                activeUrl: "/redeem",
                parent: "redeem",
                middleware: RouterPermission,
                lv: 1,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () => import("@/views/pages/redeem/index"),
                  meta: {
                    requiresAuth: true,
                    activeUrl: "/redeem",
                    parent: "redeem",
                    middleware: RouterPermission,
                    lv: 2,
                  },
                },
                {
                  path: "detail/:id",
                  name: "Detail",
                  component: () => import("@/views/pages/redeem/detail/Detail"),
                  meta: {
                    requiresAuth: true,
                    isChildren: true,
                    activeUrl: "/redeem",
                    parent: "redeem",
                    middleware: RouterPermission,
                    lv: 3,
                  },
                },
              ],
            },
          ],
          meta: { requiresAuth: true, middleware: RouterPermission },
        },
        {
          path: "",
          name: "",
          redirect: "/report/customer",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/report/customer",
              redirect: "/report/customer",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              name: "Customer",
              meta: {
                requiresAuth: true,
                activeUrl: "/report/customer",
                parent: "/report/customer",
                middleware: RouterPermission,
                lv: 1,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () =>
                    import("@/views/pages/report/customer/index"),
                  meta: {
                    requiresAuth: true,
                    activeUrl: "/report/customer",
                    parent: "/report/customer",
                    middleware: RouterPermission,
                    lv: 2,
                  },
                },
                {
                  path: ":id",
                  name: "Detail",
                  component: () =>
                    import("@/views/pages/report/customer/detail/detail"),
                  meta: {
                    requiresAuth: true,
                    isChildren: true,
                    activeUrl: "/report/customer",
                    parent: "/report/customer",
                    middleware: RouterPermission,
                    lv: 3,
                  },
                },
              ],
            },
          ],
          meta: { requiresAuth: true, middleware: RouterPermission },
        },
        {
          path: "",
          name: "",
          redirect: "/product",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/product",
              redirect: "/product",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              name: "Product",
              meta: {
                requiresAuth: true,
                activeUrl: "/product",
                parent: "/product",
                middleware: RouterPermission,
                lv: 1,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () => import("@/views/pages/productPromotion"),
                  meta: {
                    requiresAuth: true,
                    activeUrl: "/product",
                    parent: "/product",
                    middleware: RouterPermission,
                    lv: 2,
                  },
                },
                {
                  path: "detail/:id",
                  name: "Detail",
                  component: () =>
                    import("@/views/pages/productPromotion/detail/detail"),
                  meta: {
                    requiresAuth: true,
                    isChildren: true,
                    activeUrl: "/product",
                    parent: "/product",
                    middleware: RouterPermission,
                    lv: 3,
                  },
                },
                {
                  path: "imporfile",
                  name: "Import File",
                  component: () =>
                    import("@/views/pages/productPromotion/detail/importFile"),
                  meta: {
                    requiresAuth: true,
                    isChildren: true,
                    activeUrl: "/product",
                    parent: "/product",
                    middleware: RouterPermission,
                    lv: 3,
                  },
                },
              ],
            },
          ],
          meta: { requiresAuth: true, middleware: RouterPermission },
        },
        {
          path: "",
          name: "",
          redirect: "/report/transaction",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/report/transaction",
              redirect: "/report/transaction",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              name: "Transaction",
              meta: {
                requiresAuth: true,
                activeUrl: "/report/transaction",
                parent: "/report/transaction",
                middleware: RouterPermission,
                lv: 1,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () => import("@/views/pages/report/transaction"),
                  meta: {
                    requiresAuth: true,
                    activeUrl: "/report/transaction",
                    parent: "/report/transaction",
                    middleware: RouterPermission,
                    lv: 2,
                  },
                },
                {
                  path: ":id",
                  name: "Detail",
                  component: () =>
                    import("@/views/pages/report/transaction/detail/detail"),
                  meta: {
                    requiresAuth: true,
                    isChildren: true,
                    activeUrl: "/report/transaction",
                    parent: "/report/transaction",
                    middleware: RouterPermission,
                    lv: 3,
                  },
                },
                {
                  path: "/transaction/configdata",
                  name: "Config Data",
                  component: () =>
                    import("@/views/pages/transaction/import/configField.vue"),
                  meta: {
                    requiresAuth: true,
                    middleware: RouterPermission,
                    activeUrl: "/report/transaction",
                    parent: "/report/transaction",
                    lv: 3,
                  },
                },
                {
                  path: "/transaction/importdata",
                  name: "Import Data",
                  component: () =>
                    import("@/views/pages/transaction/import/ImportFile.vue"),
                  meta: {
                    requiresAuth: true,
                    middleware: RouterPermission,
                    activeUrl: "/report/transaction",
                    parent: "/report/transaction",
                  },
                },
                {
                  path: "/transaction/checkfield",
                  name: "Transaction Check Data",
                  component: () =>
                    import("@/views/pages/transaction/import/checkField.vue"),
                  meta: {
                    requiresAuth: true,
                    middleware: RouterPermission,
                    activeUrl: "/report/transaction",
                    parent: "/report/transaction",
                  },
                },
              ],
            },
          ],
          meta: { requiresAuth: true, middleware: RouterPermission },
        },

        {
          path: "/customer/importdata",
          name: "Customer Import Data",
          component: () => import("@/views/pages/customer/import/ImportFile"),
          meta: { requiresAuth: true, middleware: RouterPermission },
        },

        {
          path: "/customer/configdata",
          name: "Customer Config Data",
          component: () =>
            import("@/views/pages/customer/import/configField.vue"),
          meta: { requiresAuth: true, middleware: RouterPermission },
        },
        {
          path: "/customer/checkfield",
          name: "Customer Check Data",
          component: () =>
            import("@/views/pages/customer/import/checkField.vue"),
          meta: { requiresAuth: true, middleware: RouterPermission },
        },
        {
          path: "",
          name: "",
          redirect: "/extrapoint",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/extrapoint",
              redirect: "/extrapoint",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              name: "Extra Point",
              meta: {
                requiresAuth: true,
                activeUrl: "/extrapoint",
                parent: "/extrapoint",
                middleware: RouterPermission,
                lv: 1,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () => import("@/views/pages/extraPoint"),
                  meta: {
                    requiresAuth: true,
                    activeUrl: "/extrapoint",
                    parent: "/extrapoint",
                    middleware: RouterPermission,
                    lv: 2,
                  },
                },
                {
                  path: "details/:id",
                  name: "Detail",
                  component: () => import("@/views/pages/extraPoint/Detail"),
                  meta: {
                    requiresAuth: true,
                    isChildren: true,
                    activeUrl: "/extrapoint",
                    parent: "/extrapoint",
                    middleware: RouterPermission,
                    lv: 3,
                  },
                },
              ],
            },
          ],
          meta: { requiresAuth: true, middleware: RouterPermission },
        },
        {
          path: "",
          name: "",
          redirect: "/allrightrequest",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/allrightrequest",
              redirect: "/allrightrequest",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              name: "Right Request",
              meta: {
                requiresAuth: true,
                activeUrl: "/allrightrequest",
                parent: "/allrightrequest",
                middleware: RouterPermission,
                lv: 1,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () => import("@/views/pages/allRightRequest"),
                  meta: {
                    requiresAuth: true,
                    activeUrl: "/allrightrequest",
                    parent: "/allrightrequest",
                    middleware: RouterPermission,
                    lv: 2,
                  },
                },
                {
                  path: "detail/:id",
                  name: "Detail",
                  component: () =>
                    import("@/views/pages/allRightRequest/Detail"),
                  meta: {
                    requiresAuth: true,
                    isChildren: true,
                    activeUrl: "/allrightrequest",
                    parent: "/allrightrequest",
                    middleware: RouterPermission,
                    lv: 3,
                  },
                },
                {
                  path: "/create",
                  name: "Create Request Information",
                  component: () =>
                    import(
                      "@/views/pages/allRightRequest/createAllRightRequest/CreateAllRightRequest"
                    ),
                  meta: {
                    requiresAuth: true,
                    middleware: RouterPermission,
                    isChildren: true,
                    activeUrl: "/allrightrequest",
                  },
                },
              ],
            },
          ],
          meta: { requiresAuth: true, middleware: RouterPermission },
        },
        {
          path: "",
          name: "",
          redirect: "/activitylog",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/activitylog",
              redirect: "/activitylog",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              name: "Activity Log",
              meta: {
                requiresAuth: true,
                activeUrl: "/activitylog",
                parent: "/activitylog",
                middleware: RouterPermission,
                lv: 1,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () => import("@/views/pages/activityLog"),
                  meta: {
                    requiresAuth: true,
                    activeUrl: "/activitylog",
                    parent: "/activitylog",
                    middleware: RouterPermission,
                    lv: 2,
                  },
                },
                {
                  path: "detail/:id",
                  name: "Detail",
                  component: () => import("@/views/pages/activityLog/Detail"),
                  meta: {
                    requiresAuth: true,
                    isChildren: true,
                    activeUrl: "/activitylog",
                    parent: "/activitylog",
                    middleware: RouterPermission,
                    lv: 3,
                  },
                },
              ],
            },
          ],
          meta: { requiresAuth: true, middleware: RouterPermission },
        },
        {
          path: "",
          name: "",
          redirect: "/setting",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/setting",
              redirect: "/setting",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              name: "Setting",
              meta: {
                requiresAuth: true,
                activeUrl: "/setting",
                middleware: RouterPermission,
                lv: 2,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () => import("@/views/pages/setting"),
                  meta: {
                    activeUrl: "/setting",
                    isChildren: true,
                    requiresAuth: true,
                    middleware: RouterPermission,
                    lv: 2,
                  },
                },
                {
                  path: "policy",
                  name: "Setting Detail",
                  component: () =>
                    import(
                      "@/views/pages/setting/settingPage/DetailPolicy.vue"
                    ),
                  meta: {
                    requiresAuth: true,
                    middleware: RouterPermission,
                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 2,
                  },
                },
                {
                  path: "bussinessinfo",
                  name: "Business Information",
                  component: () =>
                    import(
                      "@/views/pages/setting/settingPage/BusinessInfo.vue"
                    ),
                  meta: {
                    requiresAuth: true,
                    middleware: RouterPermission,
                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 2,
                  },
                },
                {
                  path: "pdpasetting",
                  name: "Email Template",
                  component: () =>
                    import("@/views/pages/setting/settingPage/EmailPDPA.vue"),
                  meta: {
                    requiresAuth: true,
                    middleware: RouterPermission,
                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 2,
                  },
                },
                {
                  path: "membership",
                  name: "Member",
                  component: () =>
                    import(
                      "@/views/pages/setting/settingPage/SettingMemberShip.vue"
                    ),
                  meta: {
                    requiresAuth: true,
                    middleware: RouterPermission,
                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 2,
                  },
                },
                {
                  path: "term",
                  name: "Term and condition",
                  component: () =>
                    import("@/views/pages/setting/settingPage/DetailTerm.vue"),
                  meta: {
                    requiresAuth: true,
                    middleware: RouterPermission,
                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 2,
                  },
                },
                {
                  path: "line",
                  name: "Line Liff",
                  component: () =>
                    import("@/views/pages/setting/settingPage/Line"),
                  meta: {
                    requiresAuth: true,
                    middleware: RouterPermission,
                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 2,
                  },
                },
                {
                  path: "general",
                  name: "System",
                  component: () =>
                    import("@/views/pages/setting/settingPage/General"),
                  meta: {
                    requiresAuth: true,
                    middleware: RouterPermission,
                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 2,
                  },
                },

                {
                  path: "",
                  name: "",
                  redirect: "ecommerce",
                  component: {
                    render(c) {
                      return c("router-view");
                    },
                  },
                  meta: {
                    requiresAuth: true,

                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 2,
                  },
                  children: [
                    {
                      path: "ecommerce",
                      name: "Ecommerce",
                      redirect: "ecommerce",
                      component: {
                        render(c) {
                          return c("router-view");
                        },
                      },
                      meta: {
                        requiresAuth: true,
                        middleware: RouterPermission,
                        activeUrl: "/setting",
                        isChildren: true,
                        lv: 2,
                      },
                      children: [
                        {
                          path: "",
                          name: "",
                          component: () =>
                            import(
                              "@/views/pages/setting/settingPage/Ecommerce"
                            ),
                          meta: {
                            requiresAuth: true,
                            middleware: RouterPermission,
                            activeUrl: "/setting",
                            isChildren: true,
                            lv: 3,
                          },
                        },
                        {
                          path: ":id",
                          name: "Detail",
                          redirect: "/setting/ecommerce",
                          component: {
                            render(c) {
                              return c("router-view");
                            },
                          },
                          meta: {
                            requiresAuth: true,
                            middleware: RouterPermission,
                            activeUrl: "/setting",
                            isChildren: true,
                            lv: 2,
                          },
                          children: [
                            {
                              path: "",
                              name: "",
                              component: () =>
                                import(
                                  "@/views/pages/setting/settingPage/Ecommerce/detail.vue"
                                ),
                              meta: {
                                requiresAuth: true,
                                middleware: RouterPermission,
                                activeUrl: "/setting",
                                isChildren: true,
                                lv: 2,
                              },
                            },
                            {
                              path: ":LogID",
                              name: "Log Detail",
                              component: () =>
                                import(
                                  "@/views/pages/setting/settingPage/Ecommerce/LogDetail.vue"
                                ),
                              meta: {
                                requiresAuth: true,
                                middleware: RouterPermission,
                                activeUrl: "/setting",
                                isChildren: true,
                                lv: 2,
                              },
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
                {
                  path: "theme",
                  name: "Theme",
                  component: () =>
                    import(
                      "@/views/pages/setting/settingPage/SettingTheme.vue"
                    ),
                  meta: {
                    requiresAuth: true,
                    middleware: RouterPermission,
                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 2,
                  },
                },
                {
                  path: "/update/term",
                  name: "Term And Condition",
                  component: () =>
                    import("@/views/pages/setting/settingPage/UpdateTerm.vue"),
                  meta: {
                    requiresAuth: true,
                    middleware: RouterPermission,
                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 2,
                  },
                },
                {
                  path: "/update/policy",
                  name: "Policy",
                  component: () =>
                    import(
                      "@/views/pages/setting/settingPage/UpdatePolicy.vue"
                    ),
                  meta: {
                    requiresAuth: true,
                    middleware: RouterPermission,
                    activeUrl: "/setting",
                    isChildren: true,
                    lv: 2,
                  },
                },
              ],
            },
          ],
        },
        {
          path: "/activitylog",
          name: "Activity Log",
          component: () => import("@/views/pages/activityLog/index.vue"),
          meta: { requiresAuth: true, middleware: RouterPermission },
        },
        {
          path: "/activitylog/detail/:id",
          name: "Activity Log Detail",
          component: () => import("@/views/pages/activityLog/Detail.vue"),
          meta: {
            requiresAuth: true,
            middleware: RouterPermission,
            isChildren: true,
            activeUrl: "/activitylog",
          },
        },
        {
          path: "",
          name: "",
          redirect: "/manageStaff",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/manageStaff",
              redirect: "/manageStaff",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              name: "Staff Management",
              meta: {
                requiresAuth: true,
                activeUrl: "/manageStaff",
                parent: "staff",
                middleware: RouterPermission,
                lv: 1,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () => import("@/views/pages/manageStaff"),
                  meta: {
                    requiresAuth: true,
                    activeUrl: "/manageStaff",
                    parent: "staff",
                    middleware: RouterPermission,
                    lv: 2,
                  },
                },
                {
                  path: "detail/:id",
                  name: "Detail",
                  component: () =>
                    import("@/views/pages/manageStaff/Detail.vue"),
                  meta: {
                    requiresAuth: true,
                    isChildren: true,
                    activeUrl: "/manageStaff",
                    parent: "staff",
                    middleware: RouterPermission,
                    lv: 3,
                  },
                },
              ],
            },
          ],
          meta: { requiresAuth: true, middleware: RouterPermission },
        },

        {
          path: "",
          redirect: "/manageRole",
          name: "",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/manageRole",
              redirect: "/manageRole",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              name: "Role",
              meta: {
                requiresAuth: true,
                activeUrl: "/manageRole",
                parent: "permission",
                middleware: RouterPermission,
                lv: 1,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () => import("@/views/pages/manageRole"),
                  meta: {
                    requiresAuth: true,
                    activeUrl: "/manageRole",
                    parent: "permission",
                    middleware: RouterPermission,
                    lv: 2,
                  },
                },
                {
                  path: "detail/:id",
                  name: "Detail",
                  component: () =>
                    import("@/views/pages/manageRole/Detail.vue"),
                  meta: {
                    requiresAuth: true,
                    isChildren: true,
                    activeUrl: "/manageRole",
                    parent: "permission",
                    middleware: RouterPermission,
                    lv: 2,
                  },
                },
              ],
            },
          ],
          meta: { requiresAuth: true, middleware: RouterPermission },
        },

        {
          path: "",
          redirect: "/managePermission",
          name: "",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/managePermission",
              redirect: "/managePermission",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              name: "Admin",
              meta: {
                requiresAuth: true,
                activeUrl: "/managePermission",
                parent: "permission",
                middleware: RouterPermission,
                lv: 1,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () => import("@/views/pages/managePermission"),
                  meta: {
                    requiresAuth: true,
                    activeUrl: "/managePermission",
                    parent: "permission",
                    middleware: RouterPermission,
                    lv: 2,
                  },
                },
                {
                  path: "detail/:id",
                  name: "Detail",
                  component: () =>
                    import("@/views/pages/managePermission/Detail.vue"),
                  meta: {
                    requiresAuth: true,
                    isChildren: true,
                    activeUrl: "/managePermission",
                    parent: "permission",
                    middleware: RouterPermission,
                    lv: 3,
                  },
                },
              ],
            },
          ],
          meta: { requiresAuth: true, middleware: RouterPermission },
        },

        {
          path: "",
          name: "",
          redirect: "/manageBranch",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "/manageBranch",
              redirect: "/manageBranch",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              name: "Branch Management",
              meta: {
                requiresAuth: true,
                activeUrl: "/manageBranch",
                parent: "branch",
                middleware: RouterPermission,
                lv: 1,
              },
              children: [
                {
                  path: "",
                  name: "",
                  component: () => import("@/views/pages/manageBranch"),
                  meta: {
                    requiresAuth: true,
                    activeUrl: "/manageBranch",
                    parent: "branch",
                    middleware: RouterPermission,
                    lv: 2,
                  },
                },
                {
                  path: "detail/:id",
                  name: "Detail",
                  component: () =>
                    import("@/views/pages/manageBranch/Detail.vue"),
                  meta: {
                    requiresAuth: true,
                    isChildren: true,
                    middleware: RouterPermission,
                    activeUrl: "/manageBranch",
                    parent: "branch",
                    lv: 3,
                  },
                },
              ],
            },
          ],
          meta: { requiresAuth: true, middleware: RouterPermission },
        },
      ],
    },
    {
      path: "/500",
      name: "Page500",
      component: () => import("@/views/pages/Page500"),
    },
    {
      path: "*",
      name: "Page404",
      component: () => import("@/views/pages/Page404"),
    },
    {
      path: "/403",
      name: "Page403",
      component: () => import("@/views/pages/Page403"),
    },
  ];
}
export default router;
