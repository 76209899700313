import Vue from "vue";
import App from "./App";
import router from "./router";
import CoreuiVue from "@coreui/vue";
import "./main.css";
import { store } from "./store";
import { iconsSet as icons } from "./assets/icons/icons.js";
import { i18n } from "./plugins/i18n";
import VueCookies from "vue-cookies";
import Vuelidate from "vuelidate";
import VueMoment from "vue-moment";
import vueNumeralFilterInstaller from "vue-numeral-filter";
const moment = require("moment");
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "babel-polyfill";
import { library } from "@fortawesome/fontawesome-svg-core";
// import { vue3Debounce } from 'vue-debounce'
import vueDebounce from "vue-debounce";
import swal from "sweetalert";
import swal2 from "sweetalert2";
import alert from "./alert";
import editor from "@tinymce/tinymce-vue";
import "tinymce/tinymce";
import "tinymce/themes/silver/theme";
import "tinymce/skins/ui/oxide/skin.min.css";
import "tinymce/skins/ui/oxide/content.min.css";
import "tinymce/skins/content/default/content.min.css";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/paste";
import "tinymce/plugins/autoresize";
import Footer from "./components/FooterAction.vue";
import Pagination from "./components/Pagination.vue";
import HeaderPanel from "./components/HeaderPanel.vue";
import InputText from "./components/inputs/InputText.vue";
import TextEditorsTiny from "./components/inputs/TextEditorsTiny.vue";
Vue.component("editor", editor);
Vue.component("FooterAction", Footer);
Vue.component("Pagination", Pagination);
Vue.component("HeaderPanel", HeaderPanel);
Vue.component("InputText", InputText);
Vue.component("TextEditorsTiny", TextEditorsTiny);
import VueClipboard from "vue-clipboard2";
Vue.prototype.default_image = require("@/assets/img/default-placeholder.png");
Vue.prototype.handleImageSrc = (e) =>
  (e.target.src = require("@/assets/img/default-placeholder.png"));
VueClipboard.config.autoSetContainer = true; // add this line
Vue.use(VueClipboard);

import {
  faStore,
  faUser,
  faBullhorn,
  faSearch,
  faFileUpload,
  faChevronUp,
  faChevronDown,
  faFilter,
  faSortDown,
  faPencilAlt,
  faTrashAlt,
  faTimesCircle,
  faFileDownload,
  faBarcode,
  faPlus,
  faMinus,
  faStar,
  faChevronLeft,
  faChevronRight,
  faHome,
  faBuilding,
  faHotel,
  faCircle,
  faArrowRight,
  faClipboard,
  faBox,
  faHandHoldingUsd,
  faEllipsisV,
  faFileInvoice,
  faCheck,
  faTimes,
  faPercent,
  faGift,
  faCalendar,
  faCheckCircle,
  faExclamationCircle,
  faCopy,
  faPlusSquare,
  faSpinner,
  faChevronCircleUp,
  faFileExport,
  faChevronCircleDown,
  faPaste,
  faCalendarAlt,
  faCaretUp,
  faEdit,
  faBirthdayCake,
  faPhone,
  faEnvelope,
  faVenus,
  faCreditCard,
  faSave,
  faClock,
  faPaperPlane,
  faStickyNote,
  faFileImport,
  faFileArchive,
  faLock,
  faInfoCircle,
  faInfo,
  faEye,
  faEyeSlash,
  faPalette,
  faLink,
  faUnlink,
  faPen,
  faCog,
} from "@fortawesome/free-solid-svg-icons";

import { faStar as faStarFar } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import "@/../node_modules/slick-carousel/slick/slick.css";
import "@/../node_modules/slick-carousel/slick/slick-theme.css";
import "bootstrap-vue/dist/bootstrap-vue-icons.min.css";

require("froala-editor/js/froala_editor.pkgd.min.js");
require("froala-editor/css/froala_editor.pkgd.min.css");
require("froala-editor/css/froala_style.min.css");
const req = require.context("froala-editor/js/plugins/", false, /\.(js)$/i);
req.keys().map((key) => {
  require(`froala-editor/js/plugins/${key.toString().slice(2)}`);
});
Vue.config.devtools = true;
Vue.config.performance = true;
Vue.use(CoreuiVue);
Vue.use(vueNumeralFilterInstaller, { locale: "en-gb" });
Vue.use(VueCookies);
Vue.use(BootstrapVue);
Vue.use(vueDebounce);
Vue.use(IconsPlugin);
Vue.use(Vuelidate);
Vue.use({
  // this is the required "install" method for Vue plugins
  install(Vue) {
    Vue.swal = swal;
    Vue.swal2 = swal2;
    Vue.prototype.$swal = swal;
    Vue.prototype.$swal2 = swal2;
  },
});
Vue.use(VueMoment, {
  moment,
});
Vue.component("datetime", Datetime);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("editor", editor);
Vue.prototype.$formatDateNew = "DD/MM/YYYY";
Vue.prototype.$formatDate = "DD MMM YYYY";
Vue.prototype.$formatDateTime = "DD MMM YYYY HH:mm";
Vue.prototype.$formatTime = "HH:mm";
Vue.prototype.$EventBus = new Vue();

library.add(
  faStore,
  faUser,
  faBullhorn,
  faSearch,
  faFilter,
  faFileUpload,
  faChevronUp,
  faChevronDown,
  faSortDown,
  faPencilAlt,
  faTrashAlt,
  faTimesCircle,
  faFileDownload,
  faEye,
  faBarcode,
  faPlus,
  faMinus,
  faStar,
  faStarFar,
  faChevronLeft,
  faChevronRight,
  faHome,
  faBuilding,
  faHotel,
  faCircle,
  faArrowRight,
  faClipboard,
  faBox,
  faHandHoldingUsd,
  faEllipsisV,
  faFileInvoice,
  faCheck,
  faTimes,
  faPercent,
  faGift,
  faCalendar,
  faCheckCircle,
  faExclamationCircle,
  faCopy,
  faPlusSquare,
  faSpinner,
  faChevronCircleUp,
  faFileExport,
  faChevronCircleDown,
  faPaste,
  faCalendarAlt,
  faCaretUp,
  faEdit,
  faBirthdayCake,
  faPhone,
  faEnvelope,
  faVenus,
  faCreditCard,
  faSave,
  faClock,
  faPaperPlane,
  faStickyNote,
  faFileImport,
  faLock,
  faInfoCircle,
  faInfo,
  faEye,
  faEyeSlash,
  faPalette,
  faLink,
  faUnlink,
  faPen,
  faCog
);
new Vue({
  el: "#app",
  i18n,
  router,
  store,
  alert,
  icons,
  template: "<App/>",
  components: {
    App,
    Footer,
  },
});
