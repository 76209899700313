import Vue from "vue";
import { store } from "../../store";
// import _nav from "../../containers/_nav";
export default async function RoutePermission({ next, to, redirect }) {
  // console.log(store.state.permission.Permission);
  if (!store.state.permission.Permission) {
    await store.dispatch("getPermission");
  }

  // console.log(to.meta);
  // console.log(_nav);
  if (!Vue.$cookies.isKey("back_office_admin_token")) return next("/");

  return next();
}
