import Vue from "vue";
import VueI18n from "vue-i18n";
import VueCookies from "vue-cookies";
import th from "../assets/lang/th.json";
import en from "../assets/lang/en.json";
const messages = {
  en: en,
  th: th,
};

Vue.use(VueI18n);
Vue.use(VueCookies);

let language = "en";

export const i18n = new VueI18n({
  locale: language, // set locale
  fallbackLocale: language,
  messages, // set locale messages
});
