<template>
  <b-row class="pagination-row">
    <b-col
      cols="12"
      md="4"
      sm="4"
      class="form-inline justify-content-center justify-content-sm-start mb-1 mb-md-0"
    >
      <p class="mb-0 p-gray text-nowrap">
        Showing
        <span id="from-row">{{
          rows == 0 ? 0 : ((filter.page - 1) * filter.take + 1) | numeral("0,0")
        }}</span>
        to
        <span id="to-row">
          {{
            filter.take * filter.page > rows
              ? rows
              : (filter.take * filter.page) | numeral("0,0")
          }}</span
        >
        of <span id="total-row">{{ rows | numeral("0,0") }}</span> entires
      </p>
    </b-col>
    <b-col
      cols="12"
      md="8"
      sm="8"
      class="form-inline justify-content-center justify-content-sm-end"
      style="line-height: 0.2"
    >
      <b-pagination
        v-model="filter.page"
        :total-rows="rows"
        :per-page="filter.take"
        @change="$emit('pagination', $event)"
        first-text="First"
        prev-text="Prev"
        next-text="Next"
        last-text="Last"
        class="mb-0"
      ></b-pagination>
      <div class="break"></div>
      <br />
      <div class="mt-1 mt-md-0 ml-0 ml-sm-1" v-if="!hideTake">
        <b-form-select
          class="page-take"
          v-model="filter.take"
          @change="$emit('handleChangeTake', $event)"
          :options="pageOptions"
        ></b-form-select>
      </div>
    </b-col>
  </b-row>
</template>
<script>
export default {
  props: {
    filter: {
      required: true,
      type: Object,
    },
    pageOptions: {
      required: false,
      type: Array,
    },
    handleChangeTake: {
      required: false,
    },
    rows: {
      required: false,
    },
    pagination: {
      required: false,
    },
    hideTake: {
      required: false,
      default: false,
    },
  },
};
</script>
<style lang="scss">
@media screen and (min-width: 667px) and (max-width: 768px) {
  .pagination-row {
    text-align: center;
    div {
      justify-content: center !important;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}
@media only screen and (max-width: 820px) and (min-width: 668px) {
  .page-take {
    max-width: 60px;
  }
}
</style>
